* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    scroll-behavior: smooth;
}

body {
    background: var(--color-bg)
}

a {
    color: unset;
    text-decoration: none;
}

.gradient__bg {
    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 0% 0%, rgba(238, 251, 255, 1) 0%, rgba(230, 244, 241, 1) 48%, rgba(88, 153, 182, 1) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 0% 0%, rgba(238, 251, 255, 1) 0%, rgba(230, 244, 241, 1) 48%, rgba(88, 153, 182, 1) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 0% 0%, rgba(238, 251, 255, 1) 0%, rgba(230, 244, 241, 1) 48%, rgba(88, 153, 182, 1) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 0% 0%, rgba(238, 251, 255, 1) 0%, rgba(230, 244, 241, 1) 48%, rgba(88, 153, 182, 1) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 0% 0%, rgba(238, 251, 255, 1) 0%, rgba(230, 244, 241, 1) 48%, rgba(88, 153, 182, 1) 100%);
}