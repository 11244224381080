::placeholder {
    color: #90b1d3;
    opacity: 1; /* Firefox */
  }

.yoga__contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: row;

    padding: 2rem;
    margin: 4rem;
    border-radius: 1rem;
    background: var(--gradient-bar)
}

.yoga__contact-heading {
    flex: 1;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    

    text-align: left;
    margin-right: 5rem;
}

.yoga__contact-heading h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
}

.yoga__contact-content {
    display: flex;
    flex-direction: column;

    text-align: left;
    color: #fff;
}

.yoga__contact-content p {
    font-family: var(--font-family);
    font-size: 12px;
    line-height: 30px;
    font-weight: 500;
}

.yoga__contact-content h1 {
    font-size: 34px;
    line-height: 45px;
    font-weight: 800;
    font-family: var(--font-family);
    max-width: 510px;
}

.yoga__contact-content h3 {
    font-family: var(--font-family);
    font-size: 24px;
    line-height: 45px;
    font-weight: 800;
}

.yoga__contact-info img {
    margin-top: 20px;
    max-width: 23px;
}

.yoga__contact-info h3 {
    font-family: var(--font-family);
    font-weight: 800;
    font-size: 18px;
    line-height: 24px;
    letter-spacing: -0.04em;
    color: #fff;
    margin-left: -250%;
    margin-right: 250%;
    text-align: center;
}

.yoga__contact-info p {
    display: flex;
    flex-direction: column;
    margin-left: -250%;
    margin-right: 250%;
    font-family: var(--font-family);
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #aacaeb;
    text-align: center;
}

.yoga__contact-name-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 50px;
    margin: 10px;
    padding: 10px;
    border-radius: 0.375rem; /* 6px */
    border-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: #E6F4F1;
    font-family: var(--font-family);
    font-size: 14px;
    background: linear-gradient(
    144.39deg,
    #042c54 -278.56%,
    rgba(27, 120, 222, 1) -78.47%,
    rgba(4, 44, 84, 1) 91.61%
  );
}

.yoga__contact-mail-input {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 500px;
    height: 50px;
    margin: 10px;
    padding: 10px;
    border-radius: 0.375rem; /* 6px */
    border-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: #E6F4F1;
    font-family: var(--font-family);
    font-size: 14px;
    background: linear-gradient(
    144.39deg,
    #042c54 -278.56%,
    rgba(27, 120, 222, 1) -78.47%,
    rgba(4, 44, 84, 1) 91.61%
  );
}

.yoga__contact-message-input {
    display: flex;
    width: 500px;
    margin: 10px;
    padding: 10px;
    border-radius: 0.375rem; /* 6px */
    border-color: transparent;
    outline: 2px solid transparent;
    outline-offset: 2px;
    color: #E6F4F1;
    font-family: var(--font-family);
    font-size: 14px;
    background: linear-gradient(
    144.39deg,
    #042c54 -278.56%,
    rgba(27, 120, 222, 1) -78.47%,
    rgba(4, 44, 84, 1) 91.61%
  );
}

.yoga__contact-btn1 {
    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 2rem;
}

.yoga__contact-btn1 button {
    background: #042c54;
    color: #E6F4F1;
    font-family: var(--font-family);
    font-weight: 700;
    font-size: 18px;
    line-height: 30px;
    padding: 0.5rem 1rem;
    border-radius: 2rem;

    border: none;
    outline: none;
    cursor: pointer;
    min-width: 150px;
}

@media screen and (max-width: 1650px) {
    .yoga__contact-info h3 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #fff;
        margin-left: -150%;
        margin-right: 150%;
        margin-top: 20px;
    }
    
    .yoga__contact-info p {
        display: flex;
        flex-direction: column;
        margin-left: -150%;
        margin-right: 150%;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #aacaeb;
        text-align: center;
    }
}

@media screen and (max-width: 1450px) {
    .yoga__contact-info h3 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #fff;
        margin-left: -100%;
        margin-right: 100%;
        margin-top: 20px;
    }
    
    .yoga__contact-info p {
        display: flex;
        flex-direction: column;
        margin-left: -100%;
        margin-right: 100%;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #aacaeb;
        text-align: center;
    }
}

@media screen and (max-width: 1450px) {
    .yoga__contact-info h3 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #fff;
        margin-left: -50%;
        margin-right: 50%;
        margin-top: 20px;
    }
    
    .yoga__contact-info p {
        display: flex;
        flex-direction: column;
        margin-left: -50%;
        margin-right: 50%;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #aacaeb;
        text-align: center;
    }
}

@media screen and (max-width: 1250px) {
    .yoga__contact {
        flex-direction: column;
    }

    .yoga__contact-heading {
        margin: 0 0 2rem 0;
    }

    .yoga__contact-info h3 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #fff;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 20px;
    }

    .yoga__contact-info p {
        display: flex;
        flex-direction: column;
        margin-left: 0%;
        margin-right: 0%;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #aacaeb;
    }
}


@media screen and (max-width: 650px) {
    .yoga__contact {
        flex-direction: column;
    }

    .yoga__contact-btn {
        margin: 2rem 0 0;
    }
}

@media screen and (max-width: 550px) {
    .yoga__contact {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .yoga__contact-content h1 {
        font-size: 18px;
        line-height: 32px;
    }

    .yoga__contact-btn button {
        font-size: 14px;
        line-height: 28px;
    }

    .yoga__contact-btn1 {
        margin-left: 0px;
    }

    .yoga__contact-info h3 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #fff;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 20px;
    }

    .yoga__contact-info p {
        display: flex;
        flex-direction: column;
        margin-left: 0%;
        margin-right: 0%;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #aacaeb;
    }

    .yoga__contact-name-input {
        margin-top: 25px;
        width: 300px;
        margin-left: 10px;
    }
    .yoga__contact-mail-input {
        width: 300px;
        margin-left: 10px;
    }
    .yoga__contact-message-input {
        width: 300px;
        margin-left: 10px;
    }

}

@media screen and (max-width: 550px) {
    .yoga__contact {
        flex-direction: column;
        margin: 4rem 2rem;
    }

    .yoga__contact-content h1 {
        font-size: 18px;
        line-height: 32px;
    }

    .yoga__contact-btn button {
        font-size: 14px;
        line-height: 28px;
    }

    .yoga__contact-info h3 {
        font-family: var(--font-family);
        font-weight: 800;
        font-size: 18px;
        line-height: 24px;
        letter-spacing: -0.04em;
        color: #fff;
        margin-left: 0%;
        margin-right: 0%;
        margin-top: 20px;
    }

    .yoga__contact-info p {
        display: flex;
        flex-direction: column;
        margin-left: 0%;
        margin-right: 0%;
        font-family: var(--font-family);
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #aacaeb;
    }
}
