@import url('https://fonts.googleapis.com/css2?family=Montserrat&display=swap');

:root {
  --font-family: 'Montserrat', sans-serif;

  --gradient-text: linear-gradient(89.97deg, #11BBEF 1.84%, #0069b9 102.67%);
  --gradient-bar: linear-gradient(103.22deg, #11BBEF -13.86%, #003660 99.55%);
  
  --color-bg: #E6F4F1;
  --color-footer : #003660;
  --color-blog: #042c54;
  --color-text: #8fbfee;
  --color-subtext: #003660;
}

.gradient__text {
    background: var(--gradient-text);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.gradient__text1 {
    background: var(--color-subtext);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.section__padding {
    padding: 4rem 6rem;
}

.section__margin {
    margin: 4rem 6rem;
}

.scale-up-center{
    -webkit-animation:scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both;
    animation:scale-up-center .4s cubic-bezier(.39,.575,.565,1.000) both
}


 @-webkit-keyframes scale-up-center{
    0%{
        -webkit-transform:scale(.5);
        transform:scale(.5)}
    100%{
        -webkit-transform:scale(1);
        transform:scale(1)
    }
}
        
@keyframes scale-up-center{0%{-webkit-transform:scale(.5);transform:scale(.5)}100%{-webkit-transform:scale(1);transform:scale(1)}}

@media screen and (max-width: 700px) {
    .section__padding {
        padding: 4rem;
    }

    .section__margin {
        margin: 4rem;
    } 
}

@media screen and (max-width: 550px) {
    .section__padding {
        padding: 4rem 2rem;
    }

    .section__margin {
        margin: 4rem 2rem;
    } 
}